.iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--white-color);
  /* Below fixes every scrolling issue on some iOS-versions */
  -webkit-overflow-scrolling: touch;
}
