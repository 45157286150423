.menuButton {
  z-index: 2;
  position: fixed;
  top: 16px;
  right: 16px;
  width: 48px;
  height: 40px;
  background-color: var(--orange-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.menu {
  z-index: 1;
  position: fixed;
  top: -220px; /* To initially hide menu. */
  left: 0;
  right: 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--white-color);
  transition: top 0.5s linear;
}

.opened {
  top: 0;
}

.menu a {
  display: block;
  padding: 4px;
  margin: 4px;
  cursor: pointer;
}
