.container {
  display: flex;
  padding: 16px;
}

.name {
  flex: 1;
  margin-right: 12px;
}

.description {
  font-style: italic;
}

.price {
  white-space: nowrap;
  margin-left: 16px;
}

@media (max-width: 800px) {
  .container {
    padding: 8px;
  }
}
