:root {
  /* Colors */
  --background-color: #f8f8f8;
  --beige-color: #f8f3ea;
  --black-color: #373b3f;
  --grey-color: #5e5b62;
  --lightgrey-color: #98a0a9;
  --orange-color: #bf9540;
  --white-color: #ffffff;

  /* Fonts */
  --noto-sans-font: "Noto Sans", sans-serif;
}
