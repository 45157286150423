.container {
  max-width: 1000px;
  margin: 0 auto;
}

.header {
  padding: 48px 16px 16px;
}

.subtitle {
  text-align: center;
}

.title {
  text-align: center;
  margin-top: 8px;
}

.masonry {
  display: flex;
  width: auto;
  margin: 16px;
}
