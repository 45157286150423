.container {
  padding: 64px 0;
  background-color: var(--beige-color);
}

.content {
  display: flex;
  max-width: 900px;
  margin: 0 auto;
}

.name {
  flex: 2;
  padding: 32px;
}

.label {
  margin-bottom: 16px;
}

.description {
  flex: 3;
  line-height: 1.5;
  padding: 24px;
}

.row {
  padding: 4px;
}

@media (max-width: 800px) {
  .container {
    padding: 32px 16px;
  }

  .content {
    flex-direction: column;
  }

  .name {
    padding: 8px;
  }

  .description {
    padding: 4px;
  }
}
