.container {
  margin: 32px;
}

.subtitle {
  text-align: center;
}

.title {
  text-align: center;
  margin: 8px 0 32px;
}

.content {
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.contactInfo {
  flex: 1;
}

.info {
  margin: 32px;
}

.label {
  margin-bottom: 8px;
}

.row {
  margin: 4px 0;
}

.day {
  display: flex;
  align-items: center;
  min-width: 300px;
  margin: 4px 0;
}

.separator {
  height: 1px;
  flex: 1;
  background-color: var(--grey-color);
  margin: 0 16px;
}

.mapContainer {
  flex: 1;
  margin: 32px;
}

.map {
  width: 100%;
}

@media (max-width: 800px) {
  .container {
    margin: 0;
  }

  .content {
    flex-direction: column;
  }

  .title {
    margin: 8px 0;
  }

  .label {
    text-align: center;
  }

  .row {
    text-align: center;
  }

  .day {
    min-width: none;
    width: 100%;
  }

  .mapContainer {
    margin: 0;
  }
}
