.container {
  margin: 48px 24px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(131, 124, 122, 0.2),
    0 4px 10px 0 rgba(143, 136, 132, 0.14);
}

.name {
  text-align: center;
  text-transform: uppercase;
  padding: 32px 16px 16px;
}

.separator {
  height: 2px;
  background: var(--orange-color);
  width: 64px;
  margin-top: 8px;
  margin: 0 auto;
}

.items {
  padding: 16px;
}

@media (max-width: 800px) {
  .container {
    margin: 32px 16px;
  }

  .items {
    padding: 8px;
  }

  .name {
    padding: 16px;
  }
}
