body {
  margin: 0;
  background: var(--background-color);
}

body,
textarea,
select,
input {
  font-family: var(--noto-sans-font);
  font-weight: 300;
  font-size: 16px;
  color: var(--black-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--orange-color);
}

a:hover {
  opacity: 0.8;
  transition: opacity 0.5s linear;
}
