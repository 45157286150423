.container {
  height: 100vh;
  background-size: cover !important;
  background-position: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 80%; /* To leave some margin on mobile. */
  max-width: 400px;
  transition: opacity 0.5s linear;
  filter: invert(1);
}

.hide {
  opacity: 0;
}

.button {
  position: absolute;
  width: 240px;
  bottom: 32px;
  background-color: var(--orange-color);
  padding: 16px;
  text-align: center;
}
