.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  /* Note that number of rows is set with style in component. */
}

.body {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
}

.h1 {
  font-size: 48px;
  font-weight: 700;
}

.h2 {
  font-size: 32px;
  font-weight: 700;
}

.h3 {
  font-size: 24px;
  font-weight: 500;
}

.h4 {
  font-size: 20px;
  font-weight: 500;
}

.label {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.meta {
  font-size: 14px;
}

@media (max-width: 800px) {
  .body {
    font-size: 16px;
  }

  .h1 {
    font-size: 32px;
  }

  .h2 {
    font-size: 24px;
  }

  .h3 {
    font-size: 20px;
  }

  .h4 {
    font-size: 18px;
  }

  .label {
    font-size: 16px;
  }

  .meta {
    font-size: 12px;
  }
}
