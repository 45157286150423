.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  z-index: 2;
}

.sticky {
  background-color: var(--white-color);
  box-shadow: 0 2px 4px rgb(0 0 0 / 16%);
  transition: background-color 0.5s, box-shadow 0.5s;
}

.links {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.links a {
  text-decoration: none;
  margin: 4px 12px;
  cursor: pointer;
}

.logo {
  width: 160px;
  margin: 0 32px;
  opacity: 0;
}

.bookContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.bookButton {
  border: 2px solid var(--white-color);
  padding: 4px 12px;
  cursor: pointer;
}

.bookButton:hover {
  opacity: 0.8;
  transition: opacity 0.5s linear;
}

.sticky .logo {
  opacity: 1;
  transition: opacity 1s linear 0.5s;
}

.sticky .bookButton {
  border-color: var(--orange-color);
}
