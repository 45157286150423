.container {
  margin: 64px 0;
}

.container div {
  outline: none;
}

.subtitle {
  text-align: center;
}

.title {
  text-align: center;
  margin: 8px 0 32px;
}

.image {
  margin: 16px;
  user-select: none;
}

@media (max-width: 800px) {
  .container {
    margin: 32px 0;
  }

  .title {
    margin: 8px 0 16px;
  }
}
